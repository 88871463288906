import { Menubar } from "primereact/menubar";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { SplitButton } from "primereact/splitbutton";
import { Button } from 'primereact/button';
// import { IconField } from 'primereact/iconfield';
// import { InputIcon } from 'primereact/inputicon';
import { useNavigate } from "react-router-dom";
import "./menuBar.css";
import { useEffect } from "react";
export const MenuBar = () => {
  const toast = useRef(null);

  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();

    navigate("/"); // Redirigez vers la page de connexion (chemin : "/")
  };

  let items = [
    {
      label: "Déconnexion",
      icon: "pi pi-sign-out",
      command: handleLogout,
    },
  ];
  const showToast = () => {
    toast.current.show({
      severity: "warn",
      summary: "Info",
      detail: "Ce service est indisponible",
      life: 2000,
    });
  };
  const end = (
    <div className="endMenu">
      <Avatar
        image="../../user.png"
        shape="circle"
        style={{ marginTop: "2%", marginRight: "6px" }}
      />
      <span style={{ paddingTop: "6%", fontSize: "15px" }}>
     Lorem Ipsom
      </span>
      <SplitButton model={items} />
      <i className="pi pi-bell" style={{ paddingTop: "6%" }} onClick={showToast}/>
    </div>
  );
  const start = (
    <div className="sideStart">
  <div className="logo">

<Button className="Menu_gex" ><img alt="logo" src="../../../logo.png" height="25" className="mr-2"></img></Button>
  

</div>
<div className="searchBox">
      {/* <IconField iconPosition="right"> */}
        {/* <InputIcon className="pi pi-search"> </InputIcon> */}
        <InputText v-model="value1" placeholder="Chercher" />
      {/* </IconField> */}
    </div>

    </div>
 
  );
  return (
    <div className="">
         <Toast ref={toast} />
      <Menubar start={start} end={end} />
    </div>
  );
};
