import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import "./scan.css";

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
      <p>Loading model...</p>
    </div>
  );
};

const ModelViewer = () => {
  // Charger l'OBJ avec son MTL
  const loadModel = () => {
    return new Promise((resolve, reject) => {
      const mtlLoader = new MTLLoader();
      mtlLoader.setPath('/model/');
      mtlLoader.load('textured.mtl', (materials) => {
        materials.preload();
        const objLoader = new OBJLoader();
        objLoader.setMaterials(materials);
        objLoader.setPath('/model/');
        objLoader.load(
          'textured.obj',
          (object) => resolve(object),
          undefined,
          (error) => reject(error)
        );
      });
    });
  };

  // Utiliser React's useEffect pour charger le modèle
  const [model, setModel] = React.useState(null);

  React.useEffect(() => {
    loadModel().then(setModel).catch(console.error);
  }, []);

  return (
    <div className="canvasstyle">
      <Canvas>
        <Suspense fallback={<Loader />}>
          <ambientLight intensity={0.5} />
          <directionalLight position={[4, 8, 10]} />
          <OrbitControls />
          {model && (
          <group scale={[0.2, 0.2, 0.2]}> {/* Réduire la taille à 50% */}
            <primitive object={model} />
          </group>
        )}
        </Suspense>
      </Canvas>
    </div>
  );
};

export default ModelViewer;
