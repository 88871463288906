import React, {useRef} from 'react';
// import MenuLogo from '../MenuLogo';
import Sum from './sum'
import PieChart from './pie'
import AverageProcessingTimeChart from './average'
import { Toast } from "primereact/toast";
import { Divider } from 'primereact/divider';
import './dashboard.css'

export default function Dashboard() {
    const toast = useRef(null);

     return (
        <div className="card">
        <h3 className="nombre">Tableau de bord</h3> 
        <Divider align="center"/>
           <div className="dashboard-container">
          
          <Sum />
          <div className="charts-container">
            <AverageProcessingTimeChart />
            <PieChart />
          </div>
        </div>
      
        <Toast ref={toast} />
      
      </div>
     )
}