import React, {useState} from 'react';
// import MenuLogo from '../MenuLogo';

import { MenuBar } from "../../menuBar/menuBar";
import PageContenu from '../pageContenu/pageContenu';



export default function HomePage() {

     return (
        <>
          
            <MenuBar/>
            <PageContenu />
           
        </>
     )
}