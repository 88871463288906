import React from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';  
import { Button } from 'primereact/button';

export const Second_navBar = () => {
  const itemRenderer = (item) => (
    <a className="flex align-items-center p-menuitem-link">
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
        {item.badge && <Badge className="ml-auto" value={item.badge} />}
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    </a>
);
const items = [
    {
        label: '19-02-2024',
        icon: 'pi pi-calendar-times'
    },
    {
        label: 'Adresse: Rue de vidal, leclerc sur mer',
        icon: 'pi pi-map-marker'
    },
    // {
    //     label: 'Projects',
    //     icon: 'pi pi-search',
    //     items: [
    //         {
    //             label: 'Core',
    //             icon: 'pi pi-bolt',
    //             shortcut: '⌘+S',
    //             template: itemRenderer
    //         },
    //         {
    //             label: 'Blocks',
    //             icon: 'pi pi-server',
    //             shortcut: '⌘+B',
    //             template: itemRenderer
    //         },
    //         {
    //             label: 'UI Kit',
    //             icon: 'pi pi-pencil',
    //             shortcut: '⌘+U',
    //             template: itemRenderer
    //         },
    //         {
    //             separator: true
    //         },
    //         {
    //             label: 'Templates',
    //             icon: 'pi pi-palette',
    //             items: [
    //                 {
    //                     label: 'Apollo',
    //                     icon: 'pi pi-palette',
    //                     badge: 2,
    //                     template: itemRenderer
    //                 },
    //                 {
    //                     label: 'Ultima',
    //                     icon: 'pi pi-palette',
    //                     badge: 3,
    //                     template: itemRenderer
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        label: 'GB-R 191 ',
        icon: 'pi pi-discord',
        badge: 3,
        // template: itemRenderer
    }
];

const start = <Button className="export" aria-label="export">

<span className="export-icon">Export pc</span>
<i className="pi pi-cloud-download" style={{paddingLeft:"10px"}}></i>
</Button>;


return (
    <div className="second_nav">
        <Menubar model={items} start={start} />
    </div>
)
}