import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ConfirmPopup = ({ visible, message, onConfirm, onCancel }) => {
  return (
    <Dialog
      header="Confirmation"
      visible={visible}
      onHide={onCancel}
      style={{ width: "30vw" }}
    >
      <p style={{ marginBottom: "5%" }}>{message}</p>

      <Button
        style={{ marginLeft: "20%", width: "30%", backgroundColor: "#182a4e" }}
        label="Oui"
        icon="pi pi-check"
        onClick={onConfirm}
      />
      <Button
        style={{ marginLeft: "10%", width: "30%", backgroundColor: "#182a4e" }}
        label="Non"
        icon="pi pi-times"
        onClick={onCancel}
        className="p-button-secondary"
      />
    </Dialog>
  );
};

export default ConfirmPopup;
