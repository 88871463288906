import React from 'react';
import AppRouter from './AppRouter'; // Importez votre composant de routage

function App() {
  return (
    <>
      {/* Autres éléments d'interface utilisateur */}
      <AppRouter />
     </>
  );
}

export default App;
