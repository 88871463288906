import React , {useState , useRef} from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from "primereact/toast";
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
export const First_navBar = () => {
const [selectedCity, setSelectedCity] = useState(null);
const toast = useRef(null);
const cities = [
    { name: 'SCAN_PC0258', code: 'NY' },
    { name: 'SCAN_PC058', code: 'RM' },
    { name: 'SCAN_PC02', code: 'LDN' },
    { name: 'SCAN_PC05258', code: 'IST' },
];


const startContent = (
    <React.Fragment>
        <div className="">
   <span style={{marginRight: "10px" }}> Scan </span>    <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" 
            placeholder="Select a Robot" className="w-full md:w-14rem" />
    </div>
    </React.Fragment>
);

const centerContent = (
    <div className="">
 
    <Chip label="Attitude"  removable/>
    <Chip label="Longitude" removable/>
    <Chip label="Altitude"  removable/>
</div>
);

const endContent = (
    <React.Fragment>
       <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <span style={{marginRight: "10px" }}>
      Coupe
      </span>
      {/* <Badge  /> */}
    </div>
    <i
      onClick={() => 
      {  toast.current.show({
            severity: "warn",
            summary: "Erreur",
            detail: "Service indisponible",
            life: 3000,
          });}
      }
      style={{ fontSize: "25px" }}
      className="pi pi-plus-circle cursor-pointer  "
    />
    <div style={{visibility:'hidden'}}><InputText  /></div>
    </React.Fragment>
);

return (
    <div className="first_nav">
            <Toast ref={toast} />
        <Toolbar start={startContent} center={centerContent} end={endContent} />
    </div>
);
}