import React, {useState} from 'react';

import { Routes, Route } from "react-router-dom";

import Dashboard from '../component/dashboard/dashboard';
import {Reservation} from '../component/reservation/reservation';
import './navPage.css'
import { Scan } from '../component/Scan/scan';
export default function NavPage() {
  
   
  

    return (
        <div className="navPage">
        <Routes>
      <Route>
        <Route path="/dashboard" element={<Dashboard />} />    
        <Route path="/reservation" element={<Reservation />} />  
        <Route path="/scan" element={<Scan />} />  
      </Route>
    </Routes>

        </div>
    )
}