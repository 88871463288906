// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.link {
    padding-block-end: 10%;
}
a {
 text-decoration: none;
}
svg.icon {
    color:#252C32;
     
} */

.sideBarElement {
    padding-left: 1%;
    padding-right: 2%;
    padding-top:2%;
    width: 13%;
    background-color: whitesmoke;
}
a { 
    text-decoration: none;
    color:#374151;
} 
a.Link , a.Link:visited {
    color: #646566 !important;
    text-decoration: none;
  }
  
 .link-item:hover {
    
    color: #445137;
    
  }
  span.font-bold.title ,   span.font-bold.title:visited{
    color: #011983 !important;
  }
  a.Link.active{
     color: #172B4D !important;
     /* background-color: #707FDD !important; */
     
  }

  .link-item {
 
    padding-top: 16px;
    padding-bottom: 17px;
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
 }
 .link-item.active, .link-item:active , .link-item:focus, .link-item:hover {
  background-color: #6366f130  !important;
  color: #172B4D;
  font-weight: 500;
  font-size: 15px;
 }`, "",{"version":3,"sources":["webpack://./src/component/sideBar/sideBar.css"],"names":[],"mappings":"AAAA;;;;;;;;;;GAUG;;AAEH;IACI,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,UAAU;IACV,4BAA4B;AAChC;AACA;IACI,qBAAqB;IACrB,aAAa;AACjB;AACA;IACI,yBAAyB;IACzB,qBAAqB;EACvB;;CAED;;IAEG,cAAc;;EAEhB;EACA;IACE,yBAAyB;EAC3B;EACA;KACG,yBAAyB;KACzB,0CAA0C;;EAE7C;;EAEA;;IAEE,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;CAClB;CACA;EACC,uCAAuC;EACvC,cAAc;EACd,gBAAgB;EAChB,eAAe;CAChB","sourcesContent":["/* \r\n.link {\r\n    padding-block-end: 10%;\r\n}\r\na {\r\n text-decoration: none;\r\n}\r\nsvg.icon {\r\n    color:#252C32;\r\n     \r\n} */\r\n\r\n.sideBarElement {\r\n    padding-left: 1%;\r\n    padding-right: 2%;\r\n    padding-top:2%;\r\n    width: 13%;\r\n    background-color: whitesmoke;\r\n}\r\na { \r\n    text-decoration: none;\r\n    color:#374151;\r\n} \r\na.Link , a.Link:visited {\r\n    color: #646566 !important;\r\n    text-decoration: none;\r\n  }\r\n  \r\n .link-item:hover {\r\n    \r\n    color: #445137;\r\n    \r\n  }\r\n  span.font-bold.title ,   span.font-bold.title:visited{\r\n    color: #011983 !important;\r\n  }\r\n  a.Link.active{\r\n     color: #172B4D !important;\r\n     /* background-color: #707FDD !important; */\r\n     \r\n  }\r\n\r\n  .link-item {\r\n \r\n    padding-top: 16px;\r\n    padding-bottom: 17px;\r\n    padding-left: 15px;\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n }\r\n .link-item.active, .link-item:active , .link-item:focus, .link-item:hover {\r\n  background-color: #6366f130  !important;\r\n  color: #172B4D;\r\n  font-weight: 500;\r\n  font-size: 15px;\r\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
