// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideStart{
    display: flex;
    flex-direction: row;
}
.searchBox input.p-inputtext.p-component {
    width: 500px;
}
.endMenu {
    display: flex;
    flex-direction: row;
}
button.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {
    background-color: transparent !important;
    border-color: transparent !important;
    color : #4b5563 !important;
  }
  .searchBox {
    padding-top: 0%;
}`, "",{"version":3,"sources":["webpack://./src/menuBar/menuBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,wCAAwC;IACxC,oCAAoC;IACpC,0BAA0B;EAC5B;EACA;IACE,eAAe;AACnB","sourcesContent":[".sideStart{\n    display: flex;\n    flex-direction: row;\n}\n.searchBox input.p-inputtext.p-component {\n    width: 500px;\n}\n.endMenu {\n    display: flex;\n    flex-direction: row;\n}\nbutton.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {\n    background-color: transparent !important;\n    border-color: transparent !important;\n    color : #4b5563 !important;\n  }\n  .searchBox {\n    padding-top: 0%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
