// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  canvas {
width: 100%;
height: 100%;  
}
  
button.p-button.p-component.export {
    background-color: transparent  !important;
    color: rgb(70, 70, 70) !important;
    border: none !important;
    padding-top: 3px;
}
.cavasstyle{
    background-color:#f8f9fa;
    width: 95%; 
    height: 80%;

}
.first_nav .p-toolbar{
    margin-top: -26px !important;
    background-color: transparent !important;
    padding: 1px !important;

}
.second_nav{
    padding-top:10px
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/component/Scan/scan.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;EAEA;AACF,WAAW;AACX,YAAY;AACZ;;AAEA;IACI,yCAAyC;IACzC,iCAAiC;IACjC,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,wBAAwB;IACxB,UAAU;IACV,WAAW;;AAEf;AACA;IACI,4BAA4B;IAC5B,wCAAwC;IACxC,uBAAuB;;AAE3B;AACA;IACI;AACJ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;EAClB,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,0BAA0B;EAC1B,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["html, body, #root {\n    margin: 0;\n    padding: 0;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n  }\n  \n  canvas {\nwidth: 100%;\nheight: 100%;  \n}\n  \nbutton.p-button.p-component.export {\n    background-color: transparent  !important;\n    color: rgb(70, 70, 70) !important;\n    border: none !important;\n    padding-top: 3px;\n}\n.cavasstyle{\n    background-color:#f8f9fa;\n    width: 95%; \n    height: 80%;\n\n}\n.first_nav .p-toolbar{\n    margin-top: -26px !important;\n    background-color: transparent !important;\n    padding: 1px !important;\n\n}\n.second_nav{\n    padding-top:10px\n}\n\n.loader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n  color: #fff;\n  font-family: Arial, sans-serif;\n}\n\n.spinner {\n  width: 50px;\n  height: 50px;\n  border: 6px solid rgba(255, 255, 255, 0.3);\n  border-top: 6px solid #fff;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
