import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Divider } from 'primereact/divider';

export default function PieChart() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [540, 325, 702],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'), 
                        documentStyle.getPropertyValue('--yellow-500'), 
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'), 
                        documentStyle.getPropertyValue('--yellow-400'), 
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
         
     

          
    }, []);

    return (
        <div style={{paddingRight:50 , paddingBottom:40}} className="flex justify-content-end align-items-center gap-2 pie">
              <br/>
              <Divider align="center"/>
             <span style={{ color: 'grey', fontWeight: 'lighter' }}>Statut Intervention</span>    <br/>    <br/>
            <Chart type="pie" data={chartData} options={chartOptions} className="w-full md:w-25rem" />
        </div>
   
    );
}
