// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
    width: 30%;
    height:45%;
    position: absolute;
    left: 33%;
    top: 10%;

}
.login h1 img {
    padding-left: 39%;
}
.login .p-field {
    padding-bottom: 5%;
}
.login .lien {
    padding-bottom: 2%;
    padding-top:2%
}
.lien a{
    color:#18162c;
    font-weight: 400;
}
button.p-button.p-component.p-mt-2.connect {
   background:#18162c !important;
   border: none;
}
button.p-button.p-component.p-mt-2.reseaux_btn {
   background:transparent;
   border-color:#4B5768 !important;
   color:#4B5768 !important;
   width: 100%;
   display: block;
   font-weight: 500;
   font-size: 22px;

}
label.SouvenirLabel {
    margin-left: 1%;
}

`, "",{"version":3,"sources":["webpack://./src/component/login/login.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,UAAU;IACV,kBAAkB;IAClB,SAAS;IACT,QAAQ;;AAEZ;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB;AACJ;AACA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;GACG,6BAA6B;GAC7B,YAAY;AACf;AACA;GACG,sBAAsB;GACtB,+BAA+B;GAC/B,wBAAwB;GACxB,WAAW;GACX,cAAc;GACd,gBAAgB;GAChB,eAAe;;AAElB;AACA;IACI,eAAe;AACnB","sourcesContent":[".login {\r\n    width: 30%;\r\n    height:45%;\r\n    position: absolute;\r\n    left: 33%;\r\n    top: 10%;\r\n\r\n}\r\n.login h1 img {\r\n    padding-left: 39%;\r\n}\r\n.login .p-field {\r\n    padding-bottom: 5%;\r\n}\r\n.login .lien {\r\n    padding-bottom: 2%;\r\n    padding-top:2%\r\n}\r\n.lien a{\r\n    color:#18162c;\r\n    font-weight: 400;\r\n}\r\nbutton.p-button.p-component.p-mt-2.connect {\r\n   background:#18162c !important;\r\n   border: none;\r\n}\r\nbutton.p-button.p-component.p-mt-2.reseaux_btn {\r\n   background:transparent;\r\n   border-color:#4B5768 !important;\r\n   color:#4B5768 !important;\r\n   width: 100%;\r\n   display: block;\r\n   font-weight: 500;\r\n   font-size: 22px;\r\n\r\n}\r\nlabel.SouvenirLabel {\r\n    margin-left: 1%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
