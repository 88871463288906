import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast"; // Import Toast
import axios from "axios";
export const AddReservation = ({ showAddReservation, setShowAddReservation, refetch, setRefetch }) => {
    const [date, setDate] = useState(null);
    const [adresse, setAdresse] = useState("");
    const [selectedResponsable, setSelectedResponsable] = useState(null);
    const [disabledDates, setDisabledDates] = useState([]);
    const [minDate, setMinDate] = useState(null); // État pour minDate
    const toast = useRef(null); // Reference for Toast
    const responsables = [
        { name: "Ines BA", code: "NY" },
        { name: "Admin", code: "RM" },
    ];
    // Calculer aujourd’hui + 3 jours
    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() + 3); // Ajouter 3 jours
        setMinDate(today);
    }, []);
    // Fetch disabled dates on component mount
    useEffect(() => {
        const fetchDisabledDates = async () => {
            try {
                const response = await axios.get("http://151.80.151.189:7001/api/rdv/rendezvous/dates");
                const dates = response.data.dates.map(date => new Date(date)); // Convert strings to Date objects
                setDisabledDates(dates);
            } catch (error) {
                console.error("Error fetching disabled dates:", error.message);
            }
        };
        fetchDisabledDates();
    }, []);
    const handleSubmit = async () => {
        // Vérifier si tous les champs sont remplis
        if (!date || !adresse || !selectedResponsable) {
            toast.current.show({
                severity: "warn",
                summary: "Champs obligatoires",
                detail: "Veuillez remplir tous les champs avant de soumettre.",
                life: 5000, // Temps d’affichage en millisecondes
            });
            return; // Ne pas continuer si les champs sont vides
        }
        // Payload à envoyer
        const payload = {
            reservation_id: "RD_Demo_001",
            date: date,
            responsable: selectedResponsable.name,
            status: "En attente",
            adresse: adresse,
            robot_id: "RB_DEMO_001",
        };
        try {
            // Appel POST avec axios
            const response = await axios.post("http://151.80.151.189:7001/api/rdv/rendezvous/", payload);
            console.log("Response:", response.data);
            // Afficher le message de succès
            toast.current.show({
                severity: "success",
                summary: "Succès",
                detail: "Nous allons traiter votre demande. Nous reviendrons vers vous par mail pour validation.",
                life: 5000,
            });
            setRefetch(true);
            setAdresse("");
            setDate(null);
            setSelectedResponsable(null);
            setShowAddReservation(false);
        } catch (error) {
            console.error("Error posting data:", error.message);
            console.error("Details:", error.toJSON());
        }
    };
    return (
        <>
            {/* Toast pour afficher les messages */}
            <Toast ref={toast} />
            <Dialog
                header="Réservez votre Robot"
                visible={showAddReservation}
                style={{ width: "30vw", height: "35vw" }}
                onHide={() => {
                    setShowAddReservation(false);
                    setAdresse("");
                    setDate(null);
                    setSelectedResponsable(null);
                }}
            >
                <Divider />
                <div className="reservation">
                    {/* Date de réservation */}
                    <div className="p-field p-grid">
                        <label htmlFor="datedereservation" className="p-col-12 p-md-4 font-bold p-mb-2">
                            Date de réservation
                        </label>
                        <div className="p-col-12 p-md-8">
                            <Calendar
                                id="datedereservation"
                                value={date}
                                onChange={(e) => setDate(e.value)}
                                disabledDates={disabledDates}
                                minDate={minDate} // Désactiver les dates avant today+3
                                className="w-full"
                            />
                        </div>
                    </div>
                    {/* Responsable */}
                    <div className="p-field p-grid">
                        <label htmlFor="responsable" className="p-col-12 p-md-4 font-bold p-mb-2">
                            Responsable
                        </label>
                        <div className="p-col-12 p-md-8">
                            <Dropdown
                                id="responsable"
                                value={selectedResponsable}
                                onChange={(e) => setSelectedResponsable(e.value)}
                                options={responsables}
                                optionLabel="name"
                                placeholder="Sélectionner un responsable"
                                className="w-full"
                            />
                        </div>
                    </div>
                    {/* Adresse */}
                    <div className="p-field p-grid">
                        <label htmlFor="adresse" className="p-col-12 p-md-4 font-bold p-mb-2">
                            <i className="pi pi-map-marker" style={{ height: "20px", width: "20px" }}></i> Adresse
                        </label>
                        <div className="p-col-12 p-md-8">
                            <InputText
                                id="adresse"
                                className="w-full"
                                value={adresse}
                                onChange={(e) => setAdresse(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* Submit Button */}
                    <div className="p-text-center p-mt-4">
                        <button
                            style={{
                                backgroundColor: "#182A4E",
                                borderColor: "#182A4E",
                                color: "#FFFFFF",
                            }}
                            className="p-button p-component"
                            onClick={handleSubmit}
                        >
                            Réserver
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};