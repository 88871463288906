import React, {useState} from 'react'; 
import {SideBar} from '../sideBar/sideBar';
import NavPage from '../../navPage/navPage';
import './pageContenu.css'

export default function PageContenu({setBatiment, batiment ,setEtages, etages}) {
    const [idTicket, setIdTicket] = useState('');
    return(
        <div className="page_contenu">
          <SideBar/>
          <NavPage/>
        </div>
    )
}