import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import ConfirmPopup from "./ConfirmPopup";
import { AddReservation } from "./addReservation";
// import { clientService } from "../services/clients-service";
import { Badge } from "primereact/badge";
import axios from 'axios';
import DataTableReservation from "./DataTableReservation";
import './reservation.css'

export const Reservation = () => {
  const toast = useRef(null);
  const [refetch, setRefetch] = useState(false);
  const [reservations, setReservation] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [showAddReservation, setShowAddReservation] = useState(false);

  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [confirmVisible, setConfirmVisible] = useState(false);

  useEffect(() => {
    fetchReservation();
  }, [refetch]);

  const fetchReservation = async () => {
 
    try {
      const response = await axios.get(`http://151.80.151.189:7001/api/rdv/rendezvous`);
      setReservation(response.data)
      console.log('response', response.data);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  };

  const onRowEditComplete = async (e) => {
          toast.current.show({
        severity: "warn",
        summary: "Erreur",
        detail: "Service indisponible",
        life: 3000,
      });
    // let { newData, data } = e;
    // const ClientData = {
    //   libelle_client: newData.libelle_client,
    //   adresse_mail_client: newData.adresse_mail_client,
    //   numero_telephone_client: newData.numero_telephone_client,
    //   numero_client: data.numero_client,
    // };
    // try {
    //   setLoading(true);
    //   await clientService
    //     .update(e.data.id, ClientData)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         const updatedClients = clients.map((item) =>
    //           item.id === e.data.id ? { ...item, ...newData } : item
    //         );
    //         setClients(updatedClients);
    //         setLoading(false);
    //         toast.current.show({
    //           severity: "success",
    //           summary: "Succès",
    //           detail: "client modifiée avec succès",
    //           life: 3000,
    //         });
    //       } else {
    //         toast.current.show({
    //           severity: "error",
    //           summary: "Erreur",
    //           detail: "Erreur lors de la modification de client",
    //           life: 3000,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(
    //         "Erreur lors de la modification de l'intervention:",
    //         error
    //       );
    //       toast.current.show({
    //         severity: "error",
    //         summary: "Erreur",
    //         detail: "Erreur lors de la modification de client",
    //         life: 3000,
    //       });
    //     });
    // } catch (error) {
    //   console.error("Error updating intervention:", error);
    //   toast.current.show({
    //     severity: "error",
    //     summary: "Erreur",
    //     detail: "Erreur lors de la modification de client",
    //     life: 3000,
    //   });
    // }
  };

  const deleteClientConfirmed = async () => {
    console.log('deleted ' , reservationToDelete.id)
    try {
      await axios.delete(`http://151.80.151.189:7001/api/rdv/rendezvous/${reservationToDelete.id}`);
      setReservation(reservations.filter((re) => re.id !== reservationToDelete.id));
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Reservation supprimée",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail:
          "L'utilisateur est rattaché à un chantier. Merci de supprimer les chantiers avant de procéder à la suppression du client.",
        life: 5000,
      });
      console.error("There was an error deleting client:", error);
    } finally {
      setConfirmVisible(false);
      setReservationToDelete(null);
    }
  };

  const confirmDeleteReservation = (reservation) => {
    setReservationToDelete(reservation);
    setConfirmVisible(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
            style={{
              width: "25px",
              height: "25px",
              color: "#838384",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => confirmDeleteReservation(rowData)}
          />
        </>
      </>
    );
  };

  const handleAddClientSuccess = () => {
    fetchReservation();
  };

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmPopup
        visible={confirmVisible}
        message="Voulez-vous vraiment supprimer ce client?"
        onConfirm={deleteClientConfirmed}
        onCancel={() => setConfirmVisible(false)}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span style={{ fontWeight: "bold", marginRight: "10px" }}>
           Ajouter une réservation
          </span>
          {/* <Badge  /> */}
        </div>
        <i
          onClick={() => setShowAddReservation(true)}
          style={{ fontSize: "25px" }}
          className="pi pi-plus-circle cursor-pointer  "
        />
      </div>
      <div className="table">
     
      
   
          <DataTableReservation
            reservations={reservations}
            actionBodyTemplate={actionBodyTemplate}
            onRowEditComplete={onRowEditComplete}
          />
        
      </div>

      <AddReservation
        onSuccess={handleAddClientSuccess}
        showAddReservation={showAddReservation}
        setShowAddReservation={setShowAddReservation}
        refetch={refetch}
        setRefetch={setRefetch}
      />
    </div>
  );
};
