// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dasboard{
    display: flex;
    flex-direction: row;
   
}
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.charts-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.card {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
    margin-bottom: 20px;
}
.grid-container {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
    align-items: center;
}
.average-time {
    text-align: center;
    color: #888;
    font-weight: 300; 
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem; 
    animation: fadeIn 2s ease-in-out; 
}


/* Keyframes for fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;AAEvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,4BAA4B;IAC5B,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;IACjB,gCAAgC;AACpC;;;AAGA,oCAAoC;AACpC;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".dasboard{\n    display: flex;\n    flex-direction: row;\n   \n}\n.dashboard-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n}\n\n.charts-container {\n    display: flex;\n    justify-content: space-around;\n    width: 100%;\n}\n\n.card {\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\nh3 {\n    margin-bottom: 20px;\n}\n.grid-container {\n    display: grid;\n    grid-template-rows: auto 1fr;\n    gap: 10px;\n    align-items: center;\n}\n.average-time {\n    text-align: center;\n    color: #888;\n    font-weight: 300; \n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-size: 1.2rem; \n    animation: fadeIn 2s ease-in-out; \n}\n\n\n/* Keyframes for fade-in animation */\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
