import { NavLink } from "react-router-dom";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import "./sideBar.css";
// import { useAuth } from "../utils/UserProvider";
export const SideBar = () => {
  const toast = useRef(null);

  const showToast = () => {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: "Cette rubrique n'est pas accessible pour vous",
      life: 2000,
    });
  };
  const SidebarData = [
  
    {
      title: <span className=""> Accueil</span>,
      path: "/menu",
      icon: (
        <i
          className="pi pi-building"
          style={{ height: "20px", width: "20px" }}
        ></i>
      ),
      // disabled: !SUPER_ADMIN,
    },
    {
      title: <span className="chantier"> Tableau de Bord</span>,
      path: "/dashboard",
      icon: (
        <i
          className="pi pi-building"
          style={{ height: "20px", width: "20px" }}
        ></i>
      ),
    },
    {
      title: <span className="intervention"> Robot</span>,
      // path: "/",
      icon: (
        <i
          className="pi pi-discord"
          style={{ height: "20px", width: "20px" }}
        ></i>
      ),
      disabled:true
    },
    {
      title: <span className="intervention"> Utilisateurs</span>,
      // path: "/",
      icon: (
        <i
          className="pi pi-users"
          style={{ height: "20px", width: "20px" }}
        ></i>
      ),
      disabled:true
    },
    {
      title: <span className="intervention"> Factures</span>,
      // path: "/",
      icon: (
        <i
          className="pi pi-money-bill"
          style={{ height: "20px", width: "20px" }}
        ></i>
      ),
      disabled:true
    },
    {
      title: <span className="client"> Export</span>,
      // path: "/clients",
      icon: (
        <i
          className="pi pi-file-export"
          style={{ height: "20px", width: "20px" }}
        ></i>
      ),
      disabled:true
    },
  

    {
      title: <span className=""> Autres </span>,
    },
    {
      title: <span className="configuration"> Configuration </span>,
      path: "/configuration",
      icon: (
        <i className="pi pi-cog" style={{ height: "20px", width: "20px" }}></i>
      ),
      disabled:true
    },
  
    {
      title: <span className="aide"> Aide</span>,
      // path: "/aide",
      icon: (
        <i
          className="pi pi-exclamation-circle"
          style={{ height: "20px", width: "20px" }}
        ></i>
      ),
      disabled:true
    },
  ];

  

  return (
    <div className="sideBarElement">
      <Toast ref={toast} />
      {SidebarData.map((item, index) => {
        return (
          <div key={index}>
            <NavLink
              to={item.disabled ? "#" : item.path}
              className="Link "
              onClick={(e) => {
                if (item.disabled) {
                  e.preventDefault();
                  showToast();
                }
              }}
            >
              <div
                className="link-item  pages"
                style={
                  item.disabled
                    ? {
                        cursor: "not-allowed",
                        opacity: 0.2,
                        pointerEvents: "none",
                      }
                    : {}
                }
              >
                <span>{item.icon}</span>
                <span className="Title-item">{item.title}</span>
              </div>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};
