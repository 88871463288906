import React, { useState, useEffect } from 'react';
import { Ripple } from 'primereact/ripple';
// import config from '../config/config';
// import axios from 'axios';
// import { instance } from '../api';

export default function Sum() {
    const [nombreSociete, setNombreSociete] = useState(null);
    const [nombreEquipe, setNombreEquipe] = useState(null);
    const [nombreIntervention, setNombreIntervention] = useState(null);
    const [nombreChantier, setNombreChantier] = useState(null);
    const [loaded, setLoaded] = useState(false); 

    const fetchNumbreSociete = async () => {
        // try {
        //     const response = await instance.get(`/client/total`, {

        //     });
        //     setNombreSociete(response.data.total_clients || 0);
        // } catch (error) {
        //     console.error('Error fetching clients total number:', error);
        // }
    };

    const fetchNombreEquipes = async () => {
        // try {
        //     const response = await instance.get(`/equipes/total`, {
            
        //     });
        //     setNombreEquipe(response.data.total_equipes || 0);
        // } catch (error) {
        //     console.error('Error fetching clients total number:', error);
        // }
    };

    const fetchNombreIntervention = async () => {
        // try {
        //     const response = await instance.get(`/interventions/total`);
        //     setNombreIntervention(response.data.total_interventions || 0);
        // } catch (error) {
        //     console.error('Error fetching clients total number:', error);
        // }
    };

    const fetchNombreChantier = async () => {
        // try {
        //     const response = await instance.get(`/chantiers/total`, {

        //     });
        //     setNombreChantier(response.data.total_chantiers.total_chantiers || 0);
        // } catch (error) {
        //     console.error('Error fetching chantier total number:', error);
        // }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchNumbreSociete();
            await fetchNombreEquipes();
            await fetchNombreIntervention();
            await fetchNombreChantier();
            // setLoaded(true); 
        };

        fetchData();
    }, []);

    return (
        <div className="grid-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', width: '90%' }}>
            <div className={`p-ripple p-5 border-radius-2 nombreClient shadow-1 font-bold ${loaded ? 'animate-enter' : ''}`} style={{ backgroundColor: '#FF7246', color: 'white', height: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                <span style={{ fontSize: 10, fontWeight: 'lighter' }}><i className="pi pi-spin pi-spinner" style={{ padding:'4px' }}></i>Robots </span>
                <span style={{ fontSize: 18, fontWeight: 'bold' }}>20</span>
                <Ripple />
            </div>
            <div className={`p-ripple p-5 border-radius-2 nombreEquipe shadow-1 font-bold ${loaded ? 'animate-enter' : ''}`} style={{ backgroundColor: '#0FDB9E', color: 'white', height: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                <span style={{ fontSize: 12, fontWeight: 'lighter' }}><i className="pi pi-book" style={{ padding:'4px' }}></i> Robots Disponibles</span>
                <span style={{ fontSize: 18, fontWeight: 'bold' }}>10</span>
                <Ripple />
            </div>
            <div className={`p-ripple p-5 border-radius-2 nombreChantier shadow-1 font-bold ${loaded ? 'animate-enter' : ''}`} style={{ backgroundColor: '#6245D3', color: 'white', height: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                <span style={{ fontSize: 10, fontWeight: 'lighter' }}><i className="pi pi-calendar-times" style={{ padding:'4px' }}></i> Réservation</span>
                <span style={{ fontSize: 18, fontWeight: 'bold' }}>50</span>
                <Ripple />
            </div>
            <div className={`p-ripple p-5 border-radius-2 nombreIntervention shadow-1 font-bold ${loaded ? 'animate-enter' : ''}`} style={{ backgroundColor: '#147BFF', color: 'white', height: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gridColumn: 'span 3' }}>
                <span style={{ fontSize: 10, fontWeight: 'lighter' }}><i className="pi pi-spin pi-cog" style={{ padding:'4px' }}></i> Intervention</span>
                <span style={{ fontSize: 18, fontWeight: 'bold' }}>30</span>
                <Ripple />
            </div>
        </div>
    );
}
