import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { First_navBar } from './first_navBar';
import {Second_navBar} from './second_navBar'
import './scan.css'
import ModelViewer from './scanIframe';

export const Scan = () => {
    return (
        <>
        <First_navBar/>
        <Second_navBar/>
        <ModelViewer/>
        </>
    )
   
}