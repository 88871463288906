import React, { useRef , useState } from "react";
import "./menuGexApp.css";
import {  useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { MenuBar } from "../../menuBar/menuBar";
import { Steps } from "intro.js-react";
import 'intro.js/introjs.css';


function MenuPrincipale() {
  const [enabledAccueil, setEnabledAccueil] = useState(true);
    const navigate = useNavigate();

    const toast = useRef(null);

 

    const handleMapFactoryClick = () => {

      navigate('/scan');

      };

      let stepsAccueil = [
        {
          element: ".searchBox",
          intro: "vous pouvez effectuer des recherches",
          position: "right",
        },
        {
          element: ".pi-bell",
          intro: "vous pouvez consulter vos notifications",
          position: "right",
        },
        {
          element: ".dashboard",
          intro: "vous pouvez consulter votre tableau de Bord",
          position: "right",
        },
    
        {
          element: ".scan",
          intro: "vous pouvez consulter le scan",
          position: "right",
        },
        {
          element: ".robot",
          intro: "vous pouvez reserver un robot",
          position: "right",
        },
        {
          element: ".configuration",
          intro: "nombre des interventions",
          position: "right",
        },
        {
          element: ".aide",
          intro: "vous trouvez votre guide d'utilisateur",
          position: "right",
        },
        {
          element: ".profil",
          intro: "vous pouvez consulter votre profil",
          position: "right",
        },
      ]   

  const handleAideClick = () => {

        toast.current.show({

          severity: "warn", // Vous pouvez changer la gravité en fonction de vos besoins

          summary: "Aide",

          detail: "Service Indisponible",

          life: 3000,

        });

      };

    const handleDisconnectClick = () => {

        navigate('/');

    };

    const handleMenuClick = () => {

      navigate('/menu');

    };

    const handleHelpClick = () => {

    navigate('/help');

    };

    const handleGexAppClick = () => {
        navigate('/dashboard');
   

      };    

  

  const handleParametreClick = () => {
        toast.current.show({
          severity: "warn", // Vous pouvez changer la gravité en fonction de vos besoins
          summary: "Profil",
          detail: "Service Indisponible",
          life: 3000,
        });

   };
   let items = [
    {
      label: "Déconnexion",
      icon: "pi pi-sign-out",
      // command: handleLogout,
    },
  ];
    

const handleNotificationClick = () => {

  toast.current.show({

    severity: "warn", // Vous pouvez changer la gravité en fonction de vos besoins

    summary: "Configuration",

    detail: "Service Indisponible",

    life: 3000,

  });

};

 

const handleOrthophotosClick = () => {
  navigate('/reservation');
};        


  return (

    <div className="App">

      <header className="header">

        <nav className="nav">

   

          <Toast ref={toast} />

 <MenuBar/>



        </nav>

      </header>

          {/* <MenuLogo/> */}

      <div className="nav_menu_tool">

  

        {/* Deuxième ligne */}

        <div className="menu_row">

          <div className="menu_item dashboard" onClick={handleGexAppClick}>

          <img src="../../../dashboardpicto.png"/>

            <p className="menu_label">Tableau de bord</p>

            <hr className="menu_hr"></hr>

          </div>

          <div onClick={handleMapFactoryClick} className="menu_item scan" >

          <img src="../../../pictoPointCloud.png"/>

            <p className="menu_label">Scan</p>

            <hr className="menu_hr"></hr>

          </div>

          <div className="menu_item robot" onClick={handleOrthophotosClick}>

          <img src="../../../botpicto.png"/>

          <p className="menu_label">Robot</p>

          <hr className="menu_hr"></hr>

        </div>

        </div>

         {/* Première ligne */}

        <div className="menu_row">

        <div className="menu_item configuration" onClick={handleNotificationClick}>

          {/* <FaBell className="menu_icon" /> */}
          <img src="../../../notifpicto.png"/>

          <p className="menu_label">Configuration</p>

          <hr className="menu_hr"></hr>

        </div>

          <div className="menu_item  aide" onClick={handleAideClick}>

          {/* <FaQuestionCircle className="menu_icon" /> */}
          <img src="../../../helppicto.png"/>

          <p className="menu_label">Aide</p>

          <hr className="menu_hr"></hr>

        </div>

          <div className="menu_item profil" onClick={handleParametreClick}>

            {/* <FaCog className="menu_icon" /> */}
            <img src="../../../userpicto.png"/>

            <p className="menu_label">Profil</p>

            <hr className="menu_hr"></hr>

          </div>

        </div>

        

      </div>

  
      <Steps
        enabled={enabledAccueil}
        steps={stepsAccueil}
        initialStep={0}
        onExit={()=> {
          setEnabledAccueil(false) ; 
        }
      
        }
        options={{
          nextLabel: "Suivant",
          prevLabel: "Précedent",
          doneLabel: "Terminer",
          tooltipPosition: 'bottom',
        }}
        
      />

    </div>

  );

}

export default MenuPrincipale;