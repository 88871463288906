import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Divider } from 'primereact/divider';
// import axios from 'axios';
// import { instance } from '../api';
// import config from '../config/config';

export default function AverageProcessingTimeChart() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [averageProcessingTime, setAverageProcessingTime] = useState('');

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai'],
            datasets: [
                {
                    label: 'Moyenne du temps de traitement:',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4
                },
                {
                    label: 'Nombre de jours',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    tension: 0.4
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    
    }, []);

    return (
        <div className="card grid-container nombreInterventionHrs">
            <span className="average-time ">
            <br/>
            <Divider align="center"/>
                <i className="pi pi-calendar-clock"></i> Moyenne du temps de traitement: <br/><br/>
                {/* {averageProcessingTime} heures <i className="pi pi-arrow-down-right"></i> */}
            </span>
            <Chart type="line" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
        </div>
    );
}
