import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './component/HomePage'
import Login from './component/login';
import MenuPrincipale from './component/menuPrincipale'
function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login/>} /> 
        <Route path="/*" element={<HomePage/>} /> 
        {/* <Route exact path="/*" element={<HomePage />} /> */}
        <Route path="/menu" element={<MenuPrincipale/>} /> 
      </Routes>
    </Router>
  );
}

export default AppRouter;
