import React from "react";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Form, Field } from "react-final-form";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import { Divider } from 'primereact/divider';
import "./login.css";
export default function Login() {
   // const [checked, setChecked] = useState(false);


  const navigate = useNavigate();
  const OnSubmit = async (data) => {
    // navigate("/accueil"); 
    navigate("/menu"); 
  };
 
  // const validate = (data) => {
  //   let errors = {};

  //   if (!data.username) {
  //     errors.username = "adresse mail requise.";
  //   } else if (
  //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i.test(data.username)
  //   ) {
  //     errors.username = "adresse mail invalide";
  //   }

  //   if (!data.password) {
  //     errors.password = "mot de passe requis.";
  //   }

  //   return errors;
  // };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
    );
  };

  return (
    <div className="login">
    <h1><img alt="logo" src="../../../logo.png" height="40" className="mr-2"></img></h1>
      <Card>
          <Form
            onSubmit={OnSubmit}
            initialValues={{ username: "", password: "" }}
            // validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <Field
                  name="username"
                  render={({ input, meta }) => (
                    <div className="p-field">
                      <span className="p-float-label p-input-icon-right">
                        <InputText
                       
                          id="username"
                          {...input}
                           value="admin@admin.tn"
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="username"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Adresse mail*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <div className="p-field">
                      <span className="p-float-label">
                        <Password
                          id="password"
                          feedback={false}
                          {...input}
                          toggleMask
                          value="admin"
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="password"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Mot de passe*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    name="remember"
                    // checked={checked}
                    // onChange={(e) => setChecked(e.checked)}
                  />

                  <label htmlFor="binary" className="SouvenirLabel">
                    Se souvenir de moi
                  </label>
                </div>
                <div className="lien">
                  {" "}
                  <a href="#" className="lien_login">
                    {" "}
                    Mot de passe oublié ?{" "}
                  </a>
                </div>

                <Button
                  type="submit"
                  label="Se connecter"
                  className="p-mt-2 connect"
                />
              </form>
            )}
          />
           <Divider align="center">
                <span>Se connecter avec</span>
            </Divider>
            <div className="reseaux">
            <Button className="p-mt-2 reseaux_btn"><img alt="logo" src="../../../outlook.png" className="mr-2"></img> <span>Office 365</span> </Button>
          
            </div>
           
            
      </Card>
    </div>
  );
}
