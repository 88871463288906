// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reservation input.p-inputtext.p-component {
    margin-bottom: 5%;
    margin-top: 5%;
}
.p-dropdown{
    margin-bottom: 5%;
    margin-top: 5%;
}
button.p-button.p-component {
    margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/component/reservation/reservation.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,cAAc;AAClB","sourcesContent":[".reservation input.p-inputtext.p-component {\n    margin-bottom: 5%;\n    margin-top: 5%;\n}\n.p-dropdown{\n    margin-bottom: 5%;\n    margin-top: 5%;\n}\nbutton.p-button.p-component {\n    margin-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
