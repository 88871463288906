import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

const DataTableReservation= ({
  reservations,
  actionBodyTemplate,
  onRowEditComplete,
}) => {
  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        style={{ maxWidth: "20rem" }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  return (
    <DataTable
      value={reservations}
      tableStyle={{ minWidth: "50rem" }}
      emptyMessage="Pas de reservation."
      editMode="row"
      dataKey="id"
      onRowEditComplete={onRowEditComplete}
    >
      <Column
        field="reservation_id"
        header="Réservation ID"
        sortable
        editor={textEditor}
      />
      <Column
        field="date"
        header="Date"
        sortable
        editor={textEditor}
      />
      <Column
        field="responsable"
        header="Responsable"
        sortable
        editor={textEditor}
      />
       <Column
        field="status"
        header="Status"
        sortable
        editor={textEditor}
      />
       <Column
        field="adresse"
        header="Adresse"
        sortable
        editor={textEditor}
      />
      <Column
        field="robot_id"
        header="Robot ID"
        sortable
        editor={textEditor}
      />
     
      <Column
        body={actionBodyTemplate}
        header="Actions" 
        style={{ width: "5%", maxWidth: "2rem" }}
      />
      <Column
        rowEditor={allowEdit}
        headerStyle={{ width: "5%", maxWidth: "2rem" }}
        bodyStyle={{ textAlign: "center" }}
      />
    </DataTable>
  );
};

export default DataTableReservation;
